<template>
    <div class="home">
        <Header></Header>

        <div class="video_box">
            <video src="https://api.huazhangmedia.com/r/home_video.mp4" autoplay muted loop preload x5-playsinline="" playsinline=""
                   webkit-playsinline='' style="width:100%" id="video_data"/>
            <img src="https://api.huazhangmedia.com/r/home_cover.png" class="video_img"/>
        </div>

        <main class="show_met_21_6_12 page-content" m-id="12">
            <div class="container">
                <div class="row">
                    <div class="show_item">
                        <div class="show_item_left  col-lg-6 col-md-6 col-sm-12 animation-slide-right50 appear-no-repeat" data-plugin="appear"
                             data-animate="slide-right50" data-repeat="false">
                            <div class="show_item_left_bor">
                                <img src="./../assets/images/1538192407.jpg" class="animation-slide-left50 appear-no-repeat" alt="关于我们" data-plugin="appear"
                                     data-animate="slide-left50" data-repeat="false" style="width:100%">
                            </div>
                        </div>
                        <div class="show_item_right col-lg-6 col-md-6 col-sm-12 animation-slide-bottom50 appear-no-repeat" data-plugin="appear"
                             data-animate="slide-bottom50" data-repeat="false">
                            <div class="met-editor">
                                <p><span style="font-weight: 700; font-size: 24px; font-family: 微软雅黑, MicrosoftYaHei; color: rgb(192, 0, 0);"><br></span>
                                </p>
                                <p><span
                                        style="font-weight: 700; font-size: 24px; font-family: 微软雅黑, MicrosoftYaHei; color: rgb(192, 0, 0);">About us</span><br>
                                </p>
                                <p><span style="font-weight: 700; font-size: 24px; font-family: 微软雅黑, MicrosoftYaHei; color: rgb(192, 0, 0);">华章视觉</span><br>
                                </p>
                                <p><span style="font-family:微软雅黑, MicrosoftYaHei;"><br></span></p>
                                <p><span style="font-family:微软雅黑, MicrosoftYaHei;">
                                  中视华章（北京）科技集团有限公司，是一家集创意、拍摄、后期制作为一体的全流程视觉服务专业机构。致力于为中国以及全球客户提供全面、专业的视频相关服务，艺术影像服务和视频制作公司。</span>
                                </p>
                                <p><span style="font-family:微软雅黑, MicrosoftYaHei;"><br></span></p>
                                <p><span style="font-family:微软雅黑, MicrosoftYaHei;">中视华章投身于视觉，专情于影像。业务范围覆盖运动品牌运营、广告宣传片、栏目纪录片、商用短视频、病毒视频，视频直播，商用短视频、平面电商等等，已深耕制作视频领域十多年。</span>
                                </p>
                                <p><span style="font-family:微软雅黑, MicrosoftYaHei;"><br></span></p>
                                <p><span style="font-family:微软雅黑, MicrosoftYaHei;"></span></p>
                                <p style="white-space:normal;">中视华章拥有3个专业影棚以及自有专业摄影设备。</p>
                                <p><br></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <section class="service_list_met_16_8_5 text-xs-center noswiper background" m-id="5" data-hash data-title>
            <div class="container">
                <div class="row">
                    <div class="head col-lg-2">
                        <h3 class="title">STAR</h3>
                        <span class="line">公司优势</span>
                        <div class="line-bg"></div>
                    </div>
                    <div class="col-lg-10">
                        <ul class="service_list ulstyle blocks-md-3">
                            <li class="li" v-for="(item, index) in list" :key="index">
                                <div class="table-content">
                                    <div class="table-left col-lg-3">
                                        <img src="./../assets/images/1538208134.png" v-if="index === 0">
                                        <img src="./../assets/images/1538208813.png" v-if="index === 1">
                                        <img src="./../assets/images/1538208386.png" v-if="index === 2">
                                        <img src="./../assets/images/1538208821.png" v-if="index === 3">
                                        <img src="./../assets/images/1538208400.png" v-if="index === 4">
                                        <img src="./../assets/images/1538208629.png" v-if="index === 5">
                                    </div>
                                    <div class="table-right col-lg-9 col-sm-12">
                                        <p class="font-size-12">{{item.title_en}}</p>
                                        <div class="line-bg"></div>
                                        <p class="m-t-20 font-size-20">{{item.title}}</p>
                                    </div>
                                </div>
                                <div class="showbg">
                                    <div class="show2">
                                        <p class="t3">{{item.title}}</p>
                                        <div class="line-bg"></div>
                                        <p class="t4">{{item.desc}}</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section class="product_list_met_36_7_41 met-index-body best-room" m-id="41" id="showlist">
            <div class="container container_bg">
                <div class="title-main product_title" style="display:flex;justify-content:space-around;padding:0 80px;">
                    <div :class="active_index === 1 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(1)">
                        <h3 :style="active_index === 1 ? 'color: #CE272D' : 'color: #37474f'">[企业宣传片]</h3>
                    </div>
                    <div :class="active_index === 2 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(2)">
                        <h3 :style="active_index === 2 ? 'color: #CE272D' : 'color: #37474f'">[动画]</h3>
                    </div>
                    <div :class="active_index === 3 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(3)">
                        <h3 :style="active_index === 3 ? 'color: #CE272D' : 'color: #37474f'">[纪录片]</h3>
                    </div>
                    <div :class="active_index === 4 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(4)">
                        <h3 :style="active_index === 4 ? 'color: #CE272D' : 'color: #37474f'">[TVC广告]</h3>
                    </div>
                    <div :class="active_index === 5 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(5)">
                        <h3 :style="active_index === 5 ? 'color: #CE272D' : 'color: #37474f'">[活动]</h3>
                    </div>
                    <div :class="active_index === 6 ? 'product_h3_selected' : 'product_h3_normal'" @click="to_tab(6)">
                        <h3 :style="active_index === 6 ? 'color: #CE272D' : 'color: #37474f'">[运营]</h3>
                    </div>
                </div>
                <div class="best-room-carousel">
                    <ul class="best-room_ul no-space imagesize index-product-list tab-pane active animation-scale-up row">
                        <li class="best-room_li col-lg-4 col-md-4 col-sm-12" v-for="(item, index) in video_list" :key="index">
                            <div class="best-room_img">
                                <img class="best-room_img_image" :src="item.cover_pic" alt="" style="object-fit:cover">
                                <div class="best-room_overlay" style="display: flex; flex-direction: column; justify-content: center;"
                                     @click="previewItem(item)">
                                    <div style="margin-left: 1.5rem; font-size: 16px; color: white; font-weight: bold">
                                        <div v-if="item.title" style="display: flex; flex-direction: row;">
                                            <div style="color: #33FF33">名称：</div>
                                            {{item.title}}
                                        </div>
                                        <div v-if="item.director.name" style="display: flex; flex-direction: row;">
                                            <div style="color: #33FF33">导演：</div>
                                            {{item.director.name}}
                                        </div>
                                        <div v-if="item.info" style="display: flex; flex-direction: row;">
                                            <div style="color: #33FF33">制作：</div>
                                            {{item.info}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <a class="btn btn-brand pill" v-if="show_more_button" title="业务领域" target="_self" @click="showMore">更多案例</a>
            </div>
        </section>

        <section class="service_scope">
            <div class="container">
                <div class="service_scope_title">
                    <span class="title_line"></span>
                    <span class="title_text"><span style="font-weight: bold;">NEWS服务</span>范围</span>
                    <span class="title_line"></span>
                </div>
                <div class="service_scope_list row">
                    <div class="service_scope_detail col-lg-4 col-md-4 col-sm-12">
                        <div class="img_border">
                            <img src="./../assets/images/server1.png" style="width:100%">
                        </div>
                        <div class="service_scope_text">
                            <p class="eng">————ADVERTISING FILM</p>
                            <p class="title">宣传广告片作品</p>
                            <p class="content">美在于发现，挖掘品牌故事，</p>
                            <p class="content">用国内外先进的影视互动技术，</p>
                            <p class="content">为您带来领先的视觉体验。</p>
                            <p class="content">强大的“传播性”的“营销力”。</p>
                            <p class="content">适用于：品牌宣传、官方网站视频、</p>
                            <p class="content">产品宣传营销、网络平台广告、电视</p>
                            <p class="content">台广告等</p>
                        </div>
                    </div>
                    <div class="service_scope_detail col-lg-4 col-md-4 col-sm-12">
                        <div class="img_border">
                            <img src="./../assets/images/server2.png" style="width:100%">
                        </div>
                        <div class="service_scope_text">
                            <p class="eng">————ANIMATION&COMMERCIAL SHORT FILM</p>
                            <p class="title">动画及商用短片作品</p>
                            <p class="content">以声、像、色兼备，听、视、说并举，</p>
                            <p class="content">雕琢动画作品的每一个细节，达到</p>
                            <p class="content">企业作品与受众间的持续化</p>
                            <p class="content">深度沟通。</p>
                            <p class="content">适用于：项目洽谈、会展活动</p>
                            <p class="content">开场视频、病毒传播、招商、产品</p>
                            <p class="content">发布会、趣味短片、工业用场景等。</p>
                        </div>
                    </div>
                    <div class="service_scope_detail col-lg-4 col-md-4 col-sm-12">
                        <div class="img_border">
                            <img src="./../assets/images/server3.png" style="width:100%">
                        </div>
                        <div class="service_scope_text">
                            <p class="eng">————PHOTOGRAPHS</p>
                            <p class="title">平面、电商作品</p>
                            <p class="content">汇聚业界各方人才，针对不同产品</p>
                            <p class="content">需求打造高品质画面，一目了然、</p>
                            <p class="content">栩栩如生。不同风格，量身打造，</p>
                            <p class="content">深度产品转化营销品牌！</p>
                            <p class="content">适用于：电商产品头图、产品宣传</p>
                            <p class="content">画册、公司商务宣传图、</p>
                            <p class="content">个人公司形象等。</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="split_images">
            <img src="./../assets/images/split_img1.png" style="width: 56.5%;">
            <img src="./../assets/images/split_img2.png" style="width: 43%;">
        </div>

        <section class="partner">
            <div style="width: 100%;text-align:center;margin-top:50px;">
                <div class="partner_title">
                    <div class="title_line"></div>
                    <div class="title_text"><span style="font-weight: bold;">LOGO服务范围</span></div>
                    <div class="title_line"></div>
                </div>
                <img src="./../assets/images/partner.png" style="width: 80%;margin-top:20px;">
            </div>
        </section>

        <div class="pop" v-if="pop_close">
            <div class="close_btn" @click="pop_close = false">关闭</div>
            <div class="row">
                <div v-if="show_item.resource_type ===0 " class="poition_center col-lg-12 col-md-12 col-sm-12" v-html="show_item.video"></div>
                <div class="poition_center col-lg-12 col-md-12 col-sm-12" v-else>
                    <img :src="show_item.cover_pic" alt style>
                </div>
            </div>
        </div>

        <div></div>

        <Footer></Footer>
    </div>
</template>

<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    name: 'Home',
    components: {
      Header,
      Footer
    },
    data() {
      return {
        list: [
          {
            title: 'TVC广告',
            title_en: 'Exhibition',
            desc: 'tvc广告的优势在于它的覆盖面比较大、普及范围较广，而且一款优秀的TVC广告片具有很强的感染力可以在消费者的脑海中留下深刻印象的同时塑造企业的品牌形象，可以极大的提升品牌的知名度！'
          },
          {
            title: '宣传片',
            title_en: 'Museum scene',
            desc: '宣传片是以电视、电影的表现手法, 是对企业内部的各个层面有重点、有针对、有秩序地进行声色并茂地凸现企业独特的风格面貌、彰显企业实力，让社会不同层面的人士对企业产生良好的印象，从而建立对该企业的信任度。'
          },
          {
            title: '纪录片',
            title_en: 'Culture',
            desc: '纪录片是以真实生活为创作素材，以真人真事为表现对象，并对其进行艺术的加工与展现的，以展现真实为本质，并用真实引发人们思考的电影或电视艺术形式。纪录片的核心为真实。'
          },
          {
            title: '商业活动',
            title_en: 'Mural',
            desc: '活动方组织的活动，是以真实现场为创作题材，记录组织方这场活动的盛大，以及真人的感情流露，对现场的满意度。并用与长期保存留档，以及作为成功案例进行展示。'
          },
          {
            title: '平面拍摄',
            title_en: 'Planning',
            desc: '静物商业摄影,美食商业摄影，一站式服务,华章传媒拥有强大的视觉设计团队,产品商业摄影，先进的设备，高品质拍摄，全方位服务，快捷高效，收费合理，拥有专业'
          },
          {
            title: '抖音制作/运营',
            title_en: 'Sand table',
            desc: '抖音视频制作+运营”可以被视作 “病毒传播”的新形态。小成本大效益，我们运营团队可以借助电子邮件、视频博客以及YouTube这样的视频托管网站，视频片段在互联网上得到大面积的传播。来提高客户品牌的传播度。'
          }
        ],
        news_list: [],
        video_list: [],
        all_videios: {tvc: [], trailer: [], cartoon: [], documentary: [], activity: [], operation: []},
        active_index: 1,
        pop_close: false,
        show_item: {},
        show_more_button: true
      }
    },
    mounted() {
      setTimeout(() => {
        document.getElementById("video_data").play();
      }, 1000)
      this.getArticles();
      this.getResources();
    },
    methods: {
      getResources() {
        this.$api.getResources().then(res => {
          let videos = res.data.data;
          for (let i = 0; i < videos.length; i++) {
            this.all_videios[videos[i].category].push(videos[i]);
            this.video_list = this.all_videios.trailer.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.trailer.length;
          }
        })
      },
      getArticles() {
        this.$api.getArticles().then(res => {
          this.news_list = res.data.data;
        })
      },
      previewItem(item) {
        this.pop_close = true;
        this.show_item = item;
      },
      to_detail(item) {
        this.$router.push({path: '/new_detail', query: {title: item.title, data: item.date, content: item.content}});
      },
      to_tab(index) {
        switch (index) {
          case 1:
            this.active_index = 1;
            this.video_list = this.all_videios.trailer.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.trailer.length;
            break;
          case 2:
            this.active_index = 2;
            this.video_list = this.all_videios.cartoon.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.cartoon.length;
            break;
          case 3:
            this.active_index = 3;
            this.video_list = this.all_videios.documentary.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.documentary.length;
            break;
          case 4:
            this.active_index = 4;
            this.video_list = this.all_videios.tvc.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.tvc.length;
            break;
          case 5:
            this.active_index = 5;
            this.video_list = this.all_videios.activity.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.activity.length;
            break;
          case 6:
            this.active_index = 6;
            this.video_list = this.all_videios.operation.slice(0, 6);
            this.show_more_button = this.video_list.length < this.all_videios.operation.length;
            break;
        }
      },
      showMore() {
        switch (this.active_index) {
          case 1:
            this.video_list = this.all_videios.trailer;
            break;
          case 2:
            this.video_list = this.all_videios.cartoon;
            break;
          case 3:
            this.video_list = this.all_videios.documentary;
            break;
          case 4:
            this.video_list = this.all_videios.tvc;
            break;
          case 5:
            this.video_list = this.all_videios.activity;
            break;
          case 6:
            this.video_list = this.all_videios.operation;
            break;
        }
        this.show_more_button = false;
      }
    }
  }
</script>
<style scoped>

    @import "../assets/css/basic.css";
    @import "../assets/css/show_cn.css";

    .carousel {
        margin-top: 80px !important;
    }

    .news_list {
        margin-left: 50px !important;
        margin-right: 50px !important;
    }

    .page-content {
        text-align: left !important;
    }

    .news_detail {
        text-align: left !important;
    }

    .news_desc {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .best-room_img {
        overflow: hidden;
    }

    .best-room_img img {
        height: 200px;
        width: 355px;
        transition: all .4s;
        -moz-transition: all .4s;
        -webkit-transition: all .4s;
        -o-transition: all .4s;
    }

    /* .best-room_img img:hover {
        transform: scale(1.2);
    } */

    /*.product_h3 {*/
    /*    cursor: pointer;*/
    /*}*/

    .product_h3_selected {
        transform: scale(1.2);
        cursor: pointer;
    }

    .product_h3_normal {
        cursor: pointer;
    }

    .pop {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 3000;
    }

    .close_btn {
        color: #fff;
        font-size: 14px;
        float: right;
        margin: 40px;
        cursor: pointer;
    }

    .poition_center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .video_box {
        width: 100%;
        position: relative;
        margin-top: 70px;
    }

    .video_img {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        object-fit: contain;
    }

    @media (max-width: 767px) {
        /deep/ iframe {
            width: 400px !important;
            height: 200px !important;
        }
    }

    .container_bg {
        background: url("./../assets/images/container_bg.png");
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 800px;
    }

    .product_title {
        font-family: MFShangYa_Noncommercial;
        margin-top: 130px;
    }

    .new_list {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .new_list_item {
        margin: 30px;
        width: 362px;
        height: 386px;
        position: relative;
        padding: 10px 0;
    }

    .new_list_item_div {
        width: 326px;
        height: 148px;
        overflow: hidden;
        margin: 0 auto;
    }

    .new_list_item_image {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .new_list_item_title {
        width: 326px;
        font-size: 16px;
        font-weight: 400;
        margin: 10px auto 0;
        text-align: left;
        color: #333;
        line-height: 24px;
    }

    .new_list_item_time {
        width: 326px;
        font-size: 14px;
        margin: 0 auto;
        text-align: left;
        color: #999;
    }

    .new_list_item_content {
        width: 326px;

        margin: 18px auto 0;
        text-align: left;
        font-size: 14px;
        color: #666;
        line-height: 1.7;
    }

    .new_list_item_icon {
        display: inline-block;
        position: absolute;
        bottom: 20px;
        left: 20px;
        height: 26px;
        line-height: 26px;
        max-width: 100%;
        padding: 0 8px;
        border: 1px solid #e5e5e5;


        border-radius: 5px;
        color: #999;
        font-size: 12px;
        word-break: keep-all;
        overflow: hidden;
        z-index: 2;
    }

    .new_list_item:hover {
        transition: all .4s ease-out;
        box-shadow: 0 0 4px gray;
    }

    .new_list_item:hover .new_list_item_image {
        transform: scale(1.2);
        transition: all .4s ease-out;
    }

    .new_list_item:hover .new_list_item_title {
        color: rgb(65, 119, 219);
    }
</style>
