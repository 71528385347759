import Api from './request'

export default class App {
  constructor () {
    this.api = new Api()
  }

  async getArticles (args) {
    return await this.api.get('articles', args)
  }

  async getResources (args) {
    return await this.api.get('resources', args)
  }

  async getEmployees (args) {
    return await this.api.get('employees', args)
  }

  async createMessages (args) {
    return await this.api.post('create/message', args)
  }
}
