import axios from 'axios'

// config axios global settings
axios.defaults.timeout = 20000
axios.defaults.withCredentials = true

// add an intercepter for baas response format
axios.interceptors.response.use(response => {
  console.log('response: ', response)
  if (!response.data) {
    throw new Error('服务器连接失败')
  } else if (response.data.code !== 0) {
    throw new Error(`${response.data.msg || '未提供错误细节'}`)
  } else {
    return response
  }
}, error => {
  console.log('error: ', error)
  if (error && error.response && error.response.status === 401) {
    throw new Error('session_timeout')
  }
  return Promise.reject(error)
})

export default class Api {
  constructor () {
    this.server = 'https://api.huazhangmedia.com/'
  }

  async get (uri, params, opts = {}) {
    return axios.get(this.server + uri, { params: params }, opts)
  }

  async post (uri, params, opts = {}) {
    return axios.post(this.server + uri, this.purgeParam(params), opts)
  }

  purgeParam (args) {
    return args instanceof Object ? Object.entries(args).reduce((o, [k, v]) => {
      if (typeof v === 'boolean' || typeof v === 'number' || typeof v === 'string' || (v === null) || v) o[k] = this.purgeParam(v)
      return o
    }, args instanceof Array ? [] : {}) : args
  }
}
